var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative pb-1"},[(_vm.loading)?_c('div',{staticClass:"loader-overlay"},[_c('b-spinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary"}})],1):_vm._e(),_c('b-card',[_c('form',{ref:"offerForm",staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.submit}},[_c('validation-observer',{ref:"userInfo",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"User First Name","label-for":"First Name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"First Name"},model:{value:(_vm.value.first_name),callback:function ($$v) {_vm.$set(_vm.value, "first_name", $$v)},expression:"value.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"User Last Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Last Name"},model:{value:(_vm.value.last_name),callback:function ($$v) {_vm.$set(_vm.value, "last_name", $$v)},expression:"value.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"User Date of Birth","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"User Date of Birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('scroll-date-picker',{attrs:{"date":_vm.value.date_of_birth},model:{value:(_vm.value.date_of_birth),callback:function ($$v) {_vm.$set(_vm.value, "date_of_birth", $$v)},expression:"value.date_of_birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Gender","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-select-style",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"gender","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.genderOptions,"placeholder":"Select Gender","reduce":function (option) { return option.value; },"label":"label"},model:{value:(_vm.value.gender),callback:function ($$v) {_vm.$set(_vm.value, "gender", $$v)},expression:"value.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"mobile number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Mobile number","label-for":"mobile-number","state":(errors.length > 0) ? false : null}},[_c('VuePhoneNumberInput',{attrs:{"color":"#F4C522","error-color":"#EA5455","required":true,"error":!!errors[0],"default-country-code":"AE"},on:{"update":_vm.set_mobile_number},model:{value:(_vm.mobile_number),callback:function ($$v) {_vm.mobile_number=$$v},expression:"mobile_number"}}),_c('b-form-invalid-feedback',{staticClass:"d-flex flex-column",attrs:{"state":errors.length > 0 ? false : null}},[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobile_validate),expression:"!mobile_validate"}],staticClass:"text-danger"},[_vm._v("Mobile Number Invalid")])])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"User Email Address"}},[_c('validation-provider',{attrs:{"name":"User Email Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"User Email Address"},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", $$v)},expression:"value.email"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v(" @alaalamy.com ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.value.show_password)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":_vm.isPassword ? 'password' : 'text',"placeholder":"Password"},on:{"input":function (e) {e.length >= 8 ? _vm.validationMinLengthPassword = true : _vm.validationMinLengthPassword = false}},model:{value:(_vm.value.password),callback:function ($$v) {_vm.$set(_vm.value, "password", $$v)},expression:"value.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.isPassword ? 'EyeOffIcon' : 'EyeIcon'},on:{"click":function($event){_vm.isPassword = !_vm.isPassword}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(!_vm.validationMinLengthPassword),expression:"!validationMinLengthPassword"}],staticClass:"text-danger"},[_vm._v("Password should be at least 8 chars")])]}}],null,false,2394049886)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Creation date","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Creation date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"readonly":"","state":errors.length > 0 ? false : null,"placeholder":"Creation date"},model:{value:(_vm.value.created_at),callback:function ($$v) {_vm.$set(_vm.value, "created_at", $$v)},expression:"value.created_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-select-style",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"role","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roleOptions,"placeholder":"Select Role","reduce":function (option) { return option.id; },"label":"name"},model:{value:(_vm.value.role_id),callback:function ($$v) {_vm.$set(_vm.value, "role_id", $$v)},expression:"value.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Status: active/inactive"}},[_c('b-form-checkbox',{staticClass:"z-index-0",attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.value.status),callback:function ($$v) {_vm.$set(_vm.value, "status", $$v)},expression:"value.status"}})],1)],1)],1)],1),_c('b-button',{staticClass:"float-right",attrs:{"id":"show-btn","variant":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.action))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }