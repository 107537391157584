<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem;" variant="primary" />
    </div>
    <b-card>
      <form ref="offerForm" color="#7367F0" :title="null" :subtitle="null" shape="square" finish-button-text="Submit"
        back-button-text="Previous" class="mb-3" @on-complete="submit">
        <validation-observer ref="userInfo" tag="form">
          <b-row>
            <b-col md="6">
              <b-form-group label="User First Name" label-for="First Name">
                <validation-provider #default="{ errors }" name="First Name" rules="required">
                  <b-form-input v-model="value.first_name" :state="errors.length > 0 ? false : null"
                    placeholder="First Name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="User Last Name" label-for="name">
                <validation-provider #default="{ errors }" name="Last Name" rules="required">
                  <b-form-input v-model="value.last_name" :state="errors.length > 0 ? false : null"
                    placeholder="Last Name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="User Date of Birth" label-for="name">
                <validation-provider #default="{ errors }" name="User Date of Birth" rules="required">
                  <scroll-date-picker v-model="value.date_of_birth" :date="value.date_of_birth" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Gender" label-for="gender">
                <validation-provider #default="{ errors }" name="Gender" rules="required">
                  <v-select id="gender" class="custom-select-style"
                    :class="errors.length > 0 ? 'border-danger-for-v-select' : ''" v-model="value.gender"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="genderOptions"
                    placeholder="Select Gender" :reduce="option => option.value" label="label" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider #default="{ errors }" name="mobile number" rules="required">
                <b-form-group label="Mobile number" label-for="mobile-number"
                  :state="(errors.length > 0) ? false : null">
                  <VuePhoneNumberInput v-model="mobile_number" color="#F4C522" error-color="#EA5455"
                  :required="true" :error="!!errors[0]" default-country-code="AE" @update="set_mobile_number" />
                  <b-form-invalid-feedback class="d-flex flex-column" :state="errors.length > 0 ? false : null">
                    <span class="text-danger">{{ errors[0] }}</span>
                    <span class="text-danger" v-show="!mobile_validate">Mobile Number Invalid</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group label="User Email Address">
                <validation-provider #default="{ errors }" name="User Email Address" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input v-model="value.email" :state="errors.length > 0 ? false : null"
                      placeholder="User Email Address"></b-form-input>
                      <b-input-group-append is-text> @alaalamy.com </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" v-if="value.show_password">
              <b-form-group label="Password">
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input v-model="value.password" :state="errors.length > 0 ? false : null" :type="isPassword ? 'password' : 'text'"
                      @input="e => {e.length >= 8 ? validationMinLengthPassword = true : validationMinLengthPassword = false}"
                      placeholder="Password"></b-form-input>
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="isPassword ? 'EyeOffIcon' : 'EyeIcon'"
                        @click="isPassword = !isPassword" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-show="!validationMinLengthPassword">Password should be at least 8 chars</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Creation date" label-for="name">
                <validation-provider #default="{ errors }" name="Creation date" rules="required">
                  <b-form-datepicker readonly v-model="value.created_at" :state="errors.length > 0 ? false : null"
                    placeholder="Creation date"></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Role" label-for="role">
                <validation-provider #default="{ errors }" name="Role" rules="required">
                  <v-select id="role" class="custom-select-style"
                    :class="errors.length > 0 ? 'border-danger-for-v-select' : ''" :loading="loading"
                    v-model="value.role_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roleOptions" placeholder="Select Role" :reduce="option => option.id" label="name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Status: active/inactive">
                <b-form-checkbox v-model="value.status" name="check-button" class="z-index-0" switch inline> </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button id="show-btn" @click="submit" class="float-right" variant="primary">{{action}}</b-button>
      </form>
    </b-card>

  </div>
</template>

  <script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import DatePicker from 'vue2-datepicker'
import Cleave from 'vue-cleave-component'
import ScrollDatePicker from '../customer/ScrollDatePicker.vue'

import {
  BFormGroup,
  BFormInput, BCard,
  BRow,
  BFormInvalidFeedback,
  BCol, BInputGroupAppend,
  BInputGroup, BFormCheckbox,
  BSpinner, BFormDatepicker, BButton
} from 'bootstrap-vue'
import { required } from '@validations'
import ApiService from "@/services/shared/http/ApiService";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: {
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    FormWizard, BButton,
    BRow, BCol, BFormGroup,
    BInputGroup, BFormInput,
    TabContent, ScrollDatePicker,
    DatePicker, Cleave,
    vSelect, BFormInvalidFeedback,
    BSpinner, BCard, BInputGroupAppend,
    BFormCheckbox, VuePhoneNumberInput,
  },
  props: ['value', 'action'],
  data() {
    return {
      validationMinLengthPassword: true,
      apiService: new ApiService(),
      mobile_number: undefined,
      mobile_validate: true,
      isPassword: false,
      roleOptions: [],
      loading: false,
      required,
      genderOptions: [
        { label: "Male", value: 1 },
        { label: "Female", value: 0 },
      ],
    }
  },
  mounted() {
    this.getRoleOptions()
  },
  watch: {
    "value.email": function (newValue){
      this.value.email = newValue.replaceAll("@", "");
    },
  },
  methods: {
    submit() {
      this.$refs.userInfo.validate().then(success => {
        if (success) {
          if(this.mobile_validate && this.validationMinLengthPassword)
          this.$emit('formSubmitted')
        } else {
        }
      })
    },
    set_mobile_number(event){
      this.$emit('input', {...this.value, mobile_number: event.formattedNumber });
      this.mobile_validate = event.isValid;
    },
    getRoleOptions() {
      this.loading = true
      this.apiService.GET('roles').then(res => {
        this.roleOptions = res.data
      }).catch(() => { }).finally(() => {
        this.loading = false

      })
    }
  }
  ,
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.input-tel.is-focused .input-tel__input {
  box-shadow: unset !important;
}

.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

.loader-overlay~div {
  filter: blur(3px);
}

.z-index-0 {
  z-index: 0;
}

@media (max-width: 576px) {
  .wizard-nav.wizard-nav-pills {
    position: sticky;
    top: 70px;
    z-index: 3;
    background: #fff;
  }

  .vue-form-wizard .wizard-navigation .wizard-nav li {
    padding-bottom: 8px;
  }
}

::v-deep .dropdown-menu {
  z-index: 12;
}
</style>
