<template>
    <div>
        <!-- <b-dropdown split split-variant="outline-primary" variant="primary" text="Split Variant Dropdown" class="m-2"> -->
        <div class="picker-group">
            <scroll-picker :options="years" v-model="currentYear" />
            <scroll-picker :options="months" v-model="currentMonth" />
            <scroll-picker :options="days" v-model="currentDay" />
        </div>
        <!-- </b-dropdown> -->
    </div>
</template>

<script>
import "vue-scroll-picker/dist/style.css"
import { BDropdownItem, BDropdown } from 'bootstrap-vue'
export default {
    name: "scroll-date-picker",
    components: {
        BDropdownItem, BDropdown
    },
    props: ['value', 'date'],
    data() {
        return {
            currentYear: new Date().getFullYear(),
            currentMonth: 1,
            currentDay: 1,
        }
    },
    methods: {
        formatData() {
            if(this.date){
                const date = this.date.split('-');
                this.currentYear = +date[0];
                this.currentMonth = +date[1];
                this.currentDay = +date[2];
            }
        },
        emitterData() {
            const date = this.currentYear + '-' + this.currentMonth + '-' + this.currentDay;
            this.$emit('input', date);
        }
    },
    computed: {
        years() {
            const currYear = new Date().getFullYear()
            const lastYear = 1900
            return Array.from({ length: currYear - lastYear + 1 }, (_, index) => lastYear + index).reverse()
        },
        months() {
            return Array.from({ length: 12 }, (_, index) => index + 1)
        },
        days() {
            const lastDay = new Date(this.currentYear, this.currentMonth, 0).getDate()
            return Array.from({ length: lastDay }, (_, index) => index + 1)
        },
    },
    watch: {
        date(newValue) { this.formatData() },
        currentYear() { this.emitterData() },
        currentMonth() { this.emitterData() },
        currentDay() { this.emitterData() }
    },
    mounted() {
        console.log(this.date);
        this.formatData()
    }
}
</script>
<style scoped>
.picker-group {
    display: flex;
}
</style>