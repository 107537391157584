import CrudService from "@/services/shared/http/CrudService";

export default class UserService extends CrudService {
  constructor(params = {}) {
    super("users", params);
  }

  resetPassword(id) {
    return this.api.PATCH(`reset-password/${id}`);
  }

  changeStatus(id, value) {
    return this.api.PATCH(`user-active-inactive/${id}`, {
      status: value,
    });
  }
}
