<template>
  <div class="">
    <OfferForm ref="form" action="Update" v-model="formData" @formSubmitted="updateUser" />
  </div>
</template>
  
  <script>
import OfferForm from "@/views/components/users/UserForm.vue";
import UserService from "@/services/UserService";

export default {
  name: "UpdateOffer",
  components: {
    OfferForm
  },
  data() {
    return {
      userService: new UserService(),
      id: this.$route.params.id,
      formData: {
        creation_date: new Date(),
      },
    }
  },
  methods: {
    updateUser() {
      this.$refs.form.loading = true;
      this.formData = { ...this.formData, email: this.formData.email + '@alaalamy.com' };
      this.userService.update(this.formData.id, this.formData).then(res => {
        this.$router.push({ name: 'all-users' })
      }).catch(err => {
        this.formData = { ...this.formData, email: this.formData.email.substr(0, this.formData.email.length - 12) };
       }).finally(() => {
        this.$refs.form.loading = false;
      })
    },
    mappingData(data) {
      this.$refs.form.mobile_number = data.mobile_number;
      return {
        ...data,
        created_at: data.created_at.substr(0, data.created_at.indexOf(" ")).split('/').reverse().join('-'),
        email: data.email.substr(0, data.email.length - 13),
        status: data.status == "Active" ? true : false,
        gender: data.gender == "Male" ? 1 : 0,
        role_id: data.roles[0].id,
        show_password: false
      }
    },
    getUser() {
      this.userService.show(this.id).then(res => {
        this.formData = this.mappingData(res);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getUser()
  }
}
</script>
  
  <style scoped>
  </style>
  